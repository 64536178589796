import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ButtonVnt from "../components/button-vnt"

const TermsConditions = () => {
  return (
    <Layout>
      <SEO title="Terminos y Condiciones" />
      <div className="container page-wrapper">
        <div
          className="pricing-plane-area section_padding_100_70 clearfix"
          id="pricing"
        >
          <div className="text-center">
            <h2>Términos y Condiciones de Uso</h2>
            <p>INFORMACIÓN RELEVANTE</p>
          </div>

          <div className="text-justify p-5">
            <p>
              Es requisito necesario para la adquisición de los productos que se
              ofrecen en este sitio, que lea y acepte los siguientes Términos y
              Condiciones que a continuación se redactan. El uso de nuestros
              servicios así como la compra de nuestros productos implicará que
              usted ha leído y aceptado los Términos y Condiciones de Uso en el
              presente documento. Todas los productos que son ofrecidos por
              nuestro sitio web pudieran ser creadas, cobradas, enviadas o
              presentadas por una página web tercera y en tal caso estarían
              sujetas a sus propios Términos y Condiciones. En algunos casos,
              para adquirir un producto, será necesario el registro por parte
              del usuario, con ingreso de datos personales fidedignos y
              definición de una contraseña.
            </p>

            <p>
              El usuario puede elegir y cambiar la clave para su acceso de
              administración de la cuenta en cualquier momento, en caso de que
              se haya registrado y que sea necesario para la compra de alguno de
              nuestros productos. https://vangtry.com/ no asume la
              responsabilidad en caso de que entregue dicha clave a terceros.
            </p>

            <p>
              Todas las compras y transacciones que se lleven a cabo por medio
              de este sitio web, están sujetas a un proceso de confirmación y
              verificación, el cual podría incluir la verificación del stock y
              disponibilidad de producto, validación de la forma de pago,
              validación de la factura (en caso de existir) y el cumplimiento de
              las condiciones requeridas por el medio de pago seleccionado. En
              algunos casos puede que se requiera una verificación por medio de
              correo electrónico.
            </p>

            <p>
              Los precios de los productos ofrecidos en esta Tienda Online es
              válido solamente en las compras realizadas en este sitio web.
            </p>

            <h3>LICENCIA</h3>

            <p>
              vangTry Technology a través de su sitio web concede una licencia
              para que los usuarios utilicen los productos que son vendidos en
              este sitio web de acuerdo a los Términos y Condiciones que se
              describen en este documento.
            </p>

            <h3>TIENDA EN LÍNEA</h3>

            <p>
              Al comprar nuestro producto de tienda en línea, se ofrece hosting
              y dominio por 1 año. vangTry Technology no se hace responsable de
              los términos y condiciones estipulados por proveedor dueño de los
              recursos que serviran para que el sitio de la tienda online este
              disponible. Aclaramos que al termino de un (1) año el proveedor de
              hosting y dominiio requiere una renovacion, para lo cual vangTry
              se comunicará con el cliente de manera oportuna con el fin de
              acordar los términos de la renovacion. En caso que el cliente no
              pueda ser contactado se dará por entendido que no desea renovar el
              servicio y el proveedor podrá tomar propiedad por el alquiler de
              sus recursos bloqueando el acceso al panel de control y dando de
              baja el sitio web y todos los recursos como correos, dominios etc.
              vangTry no se hace responsable por la NO renovacion del servicio
              por falta de pago del cliente, tampoco se hace responsable si el
              pago de renovacion se hace fuera del tiempo estipulado, por la
              perdida del nombre de dominio y/o posibles aumentos en el costo de
              la renovación.
            </p>

            <h3>USO NO AUTORIZADO</h3>

            <p>
              En caso de que aplique (para venta de software, templetes, u otro
              producto de diseño y programación) usted no puede colocar uno de
              nuestros productos, modificado o sin modificar, en un CD, sitio
              web o ningún otro medio y ofrecerlos para la redistribución o la
              reventa de ningún tipo.
            </p>

            <h3>PROPIEDAD</h3>

            <p>
              Usted no puede declarar propiedad intelectual o exclusiva a
              ninguno de nuestros productos, modificado o sin modificar. Todos
              los productos son propiedad de los proveedores del contenido. En
              caso de que no se especifique lo contrario, nuestros productos se
              proporcionan sin ningún tipo de garantía, expresa o implícita. En
              ningún esta compañía será responsables de ningún daño incluyendo,
              pero no limitado a, daños directos, indirectos, especiales,
              fortuitos o consecuentes u otras pérdidas resultantes del uso o de
              la imposibilidad de utilizar nuestros productos.
            </p>

            <h3>POLÍTICA DE REEMBOLSO Y GARANTÍA</h3>

            <p>
              En el caso de productos que sean mercancías irrevocables
              no-tangibles, no realizamos reembolsos después de que se envíe el
              producto, usted tiene la responsabilidad de entender antes de
              comprarlo. Le pedimos que lea cuidadosamente antes de comprarlo.
              Hacemos solamente excepciones con esta regla cuando la descripción
              no se ajusta al producto. Hay algunos productos que pudieran tener
              garantía y posibilidad de reembolso pero este será especificado al
              comprar el producto. En tales casos la garantía solo cubrirá
              fallas de fábrica y sólo se hará efectiva cuando el producto se
              haya usado correctamente. La garantía no cubre averías o daños
              ocasionados por uso indebido. Los términos de la garantía están
              asociados a fallas de fabricación y funcionamiento en condiciones
              normales de los productos y sólo se harán efectivos estos términos
              si el equipo ha sido usado correctamente. Esto incluye:
            </p>
            <p>
              – De acuerdo a las especificaciones técnicas indicadas para cada
              producto.
              <br />– En condiciones ambientales acorde con las especificaciones
              indicadas por el fabricante.
              <br />– En uso específico para la función con que fue diseñado de
              fábrica.
              <br />– En condiciones de operación eléctricas acorde con las
              especificaciones y tolerancias indicadas.
            </p>

            <h3>COMPROBACIÓN ANTIFRAUDE</h3>

            <p>
              La compra del cliente puede ser aplazada para la comprobación
              antifraude. También puede ser suspendida por más tiempo para una
              investigación más rigurosa, para evitar transacciones
              fraudulentas.
            </p>

            <h3>PRIVACIDAD</h3>

            <p>
              Este sitio web https://vangtry.com/ garantiza que la información
              personal que usted envía cuenta con la seguridad necesaria. Los
              datos ingresados por usuario o en el caso de requerir una
              validación de los pedidos no serán entregados a terceros, salvo
              que deba ser revelada en cumplimiento a una orden judicial o
              requerimientos legales. La suscripción a boletines de correos
              electrónicos publicitarios es voluntaria y podría ser seleccionada
              al momento de crear su cuenta. vangTry Technology reserva los
              derechos de cambiar o de modificar estos términos sin previo
              aviso.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermsConditions
